import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Hidden,
  Typography
} from "@mui/material";
import React from "react";
import { BsChevronDown } from "react-icons/bs";
import colors from "../themes/colors";

interface AccordionDescriptionProps {
  id: string;
  title: string | React.ReactNode;
  text: string | React.ReactNode;
  tag?: "new" | "soon" | undefined;
  size?: "small" | "large";
  expanded?: boolean | undefined;
  defaultExpanded?: boolean | undefined;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export default function AccordionDescription({
  id,
  title,
  tag,
  text,
  size = "small",
  expanded,
  defaultExpanded,
  onClick
}: AccordionDescriptionProps) {
  const color = tag === "soon" ? colors.yellowSunny : colors.guppieGreen;

  return (
    <Accordion
      classes={{ root: "my-0 flex flex-col" }}
      defaultExpanded={defaultExpanded}
      onClick={onClick}
      expanded={expanded}
      className="shadow-none"
    >
      <AccordionSummary
        className="p-0"
        classes={{
          expandIconWrapper: tag ? "mt-12 ml-12 sm:mt-0" : "ml-8"
        }}
        expandIcon={<BsChevronDown className="relative" fontSize={28} />}
        id={id}
      >
        <Hidden mdDown>
          <Box className="flex flex-col items-start">
            {tag && (
              <Chip
                variant="filled"
                className="flex rounded-lg sm:my-0 sm:ml-6 lg:ml-0"
                sx={{ backgroundColor: colors.guppieGreen }}
                label={
                  <Typography
                    children={tag === "new" ? "Novo" : "Novo"}
                    fontSize={12}
                    className="font-medium uppercase"
                  />
                }
              />
            )}
            <Typography
              sx={{ lineHeight: size === "large" ? "2.5rem" : "" }}
              className={`font-bold flex ${size === "large" ? "line-clamp-1" : ""}`}
              fontSize={size === "large" ? 32 : 18}
              children={title}
            />
          </Box>
        </Hidden>
        <Hidden smUp>
          <Box className="flex flex-col items-start">
            {tag && (
              <Chip
                variant="filled"
                className="flex rounded-lg sm:my-0 sm:ml-6 lg:ml-0"
                sx={{ backgroundColor: color ? colors.guppieGreen : colors.guppieGreen }}
                label={
                  <Typography
                    children={tag === "new" ? "Novo" : "Novo"}
                    fontSize={12}
                    className="font-medium uppercase"
                  />
                }
              />
            )}
            <Typography
              sx={{ lineHeight: size === "large" ? "2.5rem" : "" }}
              className={`font-bold flex ${size === "large" ? "line-clamp-1" : ""}`}
              fontSize={20}
              children={title}
            />
          </Box>
        </Hidden>
      </AccordionSummary>
      <AccordionDetails className="p-0">
        <Typography
          className="font-normal border-l-4 border-solid pl-6 mb-6"
          sx={{ borderColor: colors.guppieGreen }}
          fontSize={16}
          variant="inherit"
          children={text}
        />
      </AccordionDetails>
    </Accordion>
  );
}
